import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/new-order-dine-in',
    name: 'new-order-dine-in',
    component: () => import('@/views/cashier_interface/order_transaction/NewOrder'),
    meta: {
      title: 'New Order Dine IN',
      requiresAuth: true,
      requiresCashier: true,
      requiresWaiter: true,
    },
  },
  {
    path: '/add-new-order-dine-in',
    name: 'add-new-order-dine-in',
    component: () => import('@/views/cashier_interface/order_transaction/AdditionalOrder'),
    meta: {
      title: 'Add New Order Dine IN',
      requiresAuth: true,
      requiresCashier: true,
      requiresWaiter: true,
    },
  },
  {
    path: '/fund-summary',
    name: 'fund-summary',
    component: () => import('@/views/reports_interface/FundSummary'),
    meta: {
      title: 'Callers Fund Summary',
      requiresAuth: true,
    },
  },
  {
    path: '/admin-register-users',
    name: 'admin-register-users',
    component: () => import('@/views/admin_interface_special/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-register-branch',
    name: 'admin-register-branch',
    component: () => import('@/views/admin_interface_special/NewBranch'),
    meta: {
      title: 'New Branch',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-register-wifi',
    name: 'admin-register-wifi',
    component: () => import('@/views/admin_interface_special/NewWifiVoucher'),
    meta: {
      title: 'New Wifi Voucher',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/deposit-liquidations',
    name: 'deposit-liquidations',
    component: () => import('@/views/cashier_interface/deposit_slip/Liquidation'),
    meta: {
      title: 'Deposit Slip',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
  {
    path: '/deposit-slips-data',
    name: 'deposit-slips-data',
    component: () => import('@/views/view_interface/DepositSlipsData'),
    meta: {
      title: 'Deposit Slips Data',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCashier: true,
    },
  },
  {
    path: '/bank-transaction-summary',
    name: 'bank-transaction-summary',
    component: () => import('@/views/view_interface/BankTransactionSummary'),
    meta: {
      title: 'Bank Transaction Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresManager: true,
    },
  },
  {
    path: '/admin-register-menu',
    name: 'admin-register-menu',
    component: () => import('@/views/admin_interface_special/NewMenu'),
    meta: {
      title: 'Register Menu',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-menu',
    name: 'load-menu',
    component: () => import('@/views/admin_interface_special/LoadInventory'),
    meta: {
      title: 'Register Menu',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/purchase-order-summary',
    name: 'purchase-order-summary',
    component: () => import('@/views/reports_interface/PurchaseOrderSummary'),
    meta: {
      title: 'Purchase Order Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresCashier: true,
    },
  },
  {
    path: '/sales-summary',
    name: 'sales-summary',
    component: () => import('@/views/reports_interface/SalesSummary'),
    meta: {
      title: 'SalesSummary',
      requiresAuth: true,
      requiresAdmin: true,requiresCashier: true,
    },
  },

  {
    path: '/client-payment',
    name: 'client-payment',
    component: () => import('@/views/cashier_interface/ClientsPayment'),
    meta: {
      title: 'New Order Dine IN',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
